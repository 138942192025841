<template>
    <div>
        <section class="main-banner">
            <CContainer fluid>
                <CRow>
                    <CCol sm="12">
    
                        <h1>Reservation system <span>for transportation industry</span></h1>
                        <h2>Advanced technology<br> for car & limo service</h2>
                        <md-button v-if="!temporary">Try free demo now</md-button>
                    </CCol>
                </CRow>

            </CContainer>
        </section>
      
    </div>
   
</template>

<script>
export default {
    data() {
        return {
            temporary: true
        }
    }
}
</script>
